import React, { useState, useEffect, useRef } from "react";
import { MapContainer, useMap, TileLayer, Marker, Popup, Tooltip } from "react-leaflet";
import { Form, Button, Row, Col, Modal, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

import toast, { Toaster } from "react-hot-toast";

import { security_key, BASE_URL } from "../../global";
import PhoneInput from "react-phone-input-2";
import axios from "axios";
import "./PatientLanding.css";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
// import "./AmericaMap.css";
import "./red.css";

import Moment from "moment";
import "moment-timezone";
import { colors } from "@mui/material";
import { renderToString } from "react-dom/server";

import LeafletMap from "./LeafLetMap";

Moment.tz.setDefault("America/Los_Angeles");

const currentDateAndTime = Moment();
const dbFormattedDateTime = currentDateAndTime.format("YYYY-MM-DD HH:mm:ss");
const dbFormattedDate = currentDateAndTime.format("YYYY-MM-DD");
const dbFormattedTime = currentDateAndTime.format("HH:mm:ss");

console.log(dbFormattedDateTime);

const center = [37.0902, -95.7129]; // Coordinates for the center of the United States

const CustomZoomControl = () => {
    const map = useMap();

    const handleWheel = (e) => {
        e.preventDefault(); // Prevent the default browser zoom behavior

        if (e.ctrlKey) {
            // Zoom only when the Ctrl key is pressed
            const delta = e.deltaY;
            const zoomStep = 1;

            if (delta > 0) {
                // Scroll down
                map.setZoom(map.getZoom() - zoomStep);
            } else if (delta < 0) {
                // Scroll up
                map.setZoom(map.getZoom() + zoomStep);
            }
        }
    };

    // Attach the wheel event to the map
    map.getContainer().addEventListener("wheel", handleWheel);

    return null; // This component doesn't render anything
};

function PatientLanding() {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    /****Map functional****/

    const [selectedCity, setSelectedCity] = React.useState(null);

    const handleCityClick = (data) => {
        setSelectedCity(data.name);
        navigate("/PatientAdd", { state: { city_id: data.pk_city_id, city_name: data.name, city_state: data.state } });
    };

    const customIcon = new L.Icon({
        // iconUrl: iconToUrl(<RoomIcon style={{ color: "red" }} />),
        iconSize: [32, 42],
        iconAnchor: [16, 32],
        iconUrl: "./images/marker-icon.png",
        // iconSize: [22, 22],
    });

    const zoom = selectedCity ? 12 : 4; // Adjust the zoom level as needed

    const [cities, setCities] = useState([]); // State to hold cities data

    const [allState, setAllState] = useState([]);

    useEffect(() => {
        fetchData();
        getMapCredential();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "getAllProviderCity",
            });

            const output = response.data;

            if (output.length > 0) {
                const formattedOptions = output.map((option) => ({
                    pk_city_id: option.pk_city_id,
                    state: option.State,
                    name: option.City,
                    coordinates: [option.Latitude, option.Longitude],
                }));

                setCities(formattedOptions); // Update state with fetched data

                var stateArr = output.map((row) => row.State);
                setAllState(stateArr);
            } else {
                // Handle the case when output is empty
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const [mapData, setMapData] = useState(false);

    const getMapCredential = async () => {
        setLoading(true);

        try {
            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "getMapCredential",
                map_id: 1,
            });

            const output = response.data;

            if (output) {
                setMapData(output);
                setLoading(false);
            } else {
                // Handle unsuccessful response, e.g., show an alert to the user.
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const mapContainerRef = useRef(null);

    const scrollToMapContainer = () => {
        if (mapContainerRef.current) {
            const mapContainer = mapContainerRef.current;
            const { offsetTop, clientHeight } = mapContainer;
            const scrollTarget = offsetTop + clientHeight / 2;

            // Current scroll position
            const start = window.pageYOffset;
            // Distance to scroll
            const distance = scrollTarget - start;
            // Duration of the scroll animation
            const duration = 1000; // Adjust as needed, 1000ms = 1 second
            // Timestamp at the beginning of the scroll
            const startTime = performance.now();

            // Animation function
            const animateScroll = (currentTime) => {
                const elapsedTime = currentTime - startTime;
                // Calculate the position to scroll to
                const position = easeInOut(elapsedTime, start, distance, duration);
                // Scroll to that position
                window.scrollTo(0, position);
                // Continue the animation if not finished
                if (elapsedTime < duration) {
                    requestAnimationFrame(animateScroll);
                }
            };

            // Easing function for smooth animation (you can use different easing functions)
            const easeInOut = (t, b, c, d) => {
                t /= d / 2;
                if (t < 1) return (c / 2) * t * t + b;
                t--;
                return (-c / 2) * (t * (t - 2) - 1) + b;
            };

            // Start the animation
            requestAnimationFrame(animateScroll);
        }
    };

    return (
        <>
            <Toaster />
            <div className="patient-container" style={{backgroundColor: "#fffbf7"}}>
                <div className="preloader" />
           

             
              

                <section className="map-section">
                    <Container className="map-container"> <div className="row">
                        <div className="col-12 text-center">
                        <h2>
                                            Book an <span className="text-highlight">Appointment </span> Now
                                        </h2>
                        <p className="pb-3">Book your appointment now by choosing a location on the map.</p>
                            {mapData && (
                                <MapContainer
                                    className="custom-map card"
                                    style={{ height: mapData.height + "px", width: mapData.width + "%", display: "inline-block" , }}
                                    center={center}
                                    zoom={3.5}
                                    scrollWheelZoom={mapData.scroll_wheel_zoom == 1}
                                    maxZoom={mapData.max_zoom}
                                    minZoom={mapData.min_zoom}
                                >
                                    <TileLayer
                                        noWrap={true}
                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        // attribution={false}
                                        url={mapData.theme_link}
                                        // url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
                                        // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        // url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png"
                                        // url="https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png"
                                        // url="https://tile.openstreetmap.bzh/br/{z}/{x}/{y}.png"
                                        // url="https://tiles.stadiamaps.com/tiles/alidade_satellite/{z}/{x}/{y}{r}.png"
                                        // url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                                        // url="https://api.mapbox.com/styles/mapbox/navigation-night-v1"
                                        // url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                                    />

                                    {cities.map((city, index) => (
                                        <Marker
                                            key={index}
                                            position={city.coordinates}
                                            icon={customIcon}
                                            rotation3d={[1, 0, 0, 320]}
                                            rotationAngle={320}
                                            eventHandlers={{ click: () => handleCityClick(city) }}
                                        >
                                            <Tooltip autoClose={false} direction="top" offset={[0, -33]}>
                                                {city.name}
                                            </Tooltip>
                                        </Marker>
                                    ))}

                                    <CustomZoomControl />
                                </MapContainer>
                            )}
                        </div>
                    </div></Container>
                   
                </section>

     
          


    
                <a href="#top" className="back_to_top">
                    <img src="images/back_to_top.png" alt="back to top" />
                </a>
            </div>
        </>
    );
}

export default PatientLanding;
