import React, { useState, useRef, useEffect } from "react";
import { Form, Row, Col, Button, Modal } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputMask from "react-input-mask";

import Select from "react-select";
import axios from "axios";
import { security_key, BASE_URL, options_city, options_state } from "../../global";

import toast, { Toaster } from "react-hot-toast";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";

import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import "./PatientAddU3.css";

const basicSchema = Yup.object().shape({
    patient_first_name: Yup.string()
        .min(3, "First Name must be at least 3 characters.")
        .max(250, "First Name must be at most 250 characters.")
        .matches(/^[a-zA-Z]*$/, "First Name can only contain letters.")
        .required("First Name is required.")
        .strict(),
    patient_last_name: Yup.string()
        .min(1, "Last Name must be at least 1 character.")
        .max(250, "Last Name must be at most 250 characters.")
        .matches(/^[a-zA-Z]*$/, "Last Name can only contain letters.")
        .required("Last Name is required.")
        .strict(),
    patient_mobile_phone: Yup.string()
        .required("Mobile number is required.")
        .min(11, "Mobile number must be at least 10 digits.")
        .max(16, "Mobile number must not exceed 15 digits.")
        .test("unique-digits", "Invalid mobile number", function (value) {
            // Remove leading + and any non-digit characters
            const cleanedDigits = value.replace(/^\+\D*/, "");

            // Remove the first digit (country code)
            const digitsWithoutCountryCode = cleanedDigits.slice(1);

            // Check if there is at least one different digit
            const hasDifferentDigit = Array.from(new Set(digitsWithoutCountryCode)).length !== 1;

            return hasDifferentDigit || this.createError({ message: "Invalid mobile number." });
        })

        .strict(),
    patient_email: Yup.string()
        .email("Invalid email.")
        .required("Email is required.")
        .test("valid-email.", "Invalid email format.", function (value) {
            if (!value) return true; // Allow empty value (let required() handle this)

            // Check for invalid patterns
            const invalidPatterns = [
                /^\./, // Starts with a dot
                /\.$/, // Ends with a dot
                /\.{2,}/, // Multiple dots in sequence
                /\.@/, // Starts with a dot immediately followed by @
                // /\.com.com$/,
                /(\.com){2,}$/, // Ends with .com repeated one or more times
                // /@\.\w+$/,
            ];

            const validPatterns = [/@.*\.[^.].*$/];

            const isInvalid = invalidPatterns.some((pattern) => pattern.test(value));

            const isValid = validPatterns.some((pattern) => pattern.test(value));

            return isValid && !isInvalid;
        }),

    // patient_address_1: Yup.string()
    //     .required("Patient Address 1 is required.")
    //     .min(3, "Patient Address 1 must be at least 3 characters.")
    //     .max(250, "Patient Address 1 must be at most 250 characters."),

    patient_state: Yup.string(),

    patient_zip: Yup.string().min(5, "Zip Code must be at least 5 digits.").max(10, "Zip Code must be at most 9 digits.").required("Zip Code is required."),
    // .matches(/^\d{5}$/, "Invalid ZIP code.")
    // .test("is-valid-zip", "Invalid ZIP code.", (value) => Number(value) >= 1 && Number(value) <= 99950),

    area_of_concern: Yup.string()
        .required("Area of concern is required.")
        .min(8, "Area of concern must be at least 8 characters.")
        .max(250, "Area of concern must be at most 250 characters.")
        .strict(),

    urgency: Yup.string().required("Urgency is required."),

    preferred_contact_method: Yup.string().when("urgency", {
        is: "Other",
        then: () =>
            Yup.string()
                .required("Preferred Contact Method is required.")
                .matches(/^[a-zA-Z ]*$/, "Preferred Contact Method can only contain letters and spaces.")
                .min(3, "Preferred Contact Method must be at least 3 characters.")
                .max(250, "Preferred Contact Method must be at most 250 characters."),
    }),

    // patient_schedule_date: Yup.string().required("Schedule date is required."),
});

const PatientAddU3 = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const navigate = useNavigate();

    const location = useLocation();

    let city_id = "";
    let city_name = "";
    let city_state = "";
    let allState = [];

    if (location.state.city_id) {
        ({ city_id, city_name, city_state } = location.state);
    } else {
        ({ allState } = location.state);
        console.clear();
        console.log(allState);
    }

    useEffect(() => {
        // alert(JSON.stringify(location.state));
        if (city_id == "" || city_name == "" || city_state == "") {
            navigate("/");
        }
    }, [city_state]);

    const [selectedCity, setSelectedCity] = React.useState(city_name);
    const [selectedState, setSelectedState] = React.useState(city_state);
    const [cityDetails, setCityDetails] = React.useState(`${city_id} || ${city_name.trim()} || ${city_state.trim()}`);

    const [loading, setLoading] = useState(true);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const formRef = useRef(null);

    const resetFormFields = (resetForm, setValues, initialValues) => {
        resetForm();
        setValues({
            ...initialValues,
        });
    };
    async function checkPatientEmailUniqueness(email) {
        const response = await axios.post(BASE_URL + "API/apicall", {
            security_key: security_key,
            mode: "checkPatientEmailUniqueness",
            patient_email: email,
        });

        const output = response.data;

        return output;
    }

    const initialValues = {
        /*tbl_patient_information*/
        patient_first_name: "",
        patient_last_name: "",
        patient_address_1: "",
        patient_address_2: "",
        // patient_city: selectedCity,
        // patient_state: selectedState,
        patient_city: location.state.city_id ? cityDetails : "",
        patient_state: location.state.city_id ? selectedState : "",
        patient_zip: "",
        patient_email: "",
        patient_mobile_phone: "",
        patient_other_phone: "",
        description_of_condition: "",
        urgency: "",
        preferred_contact_method: "",
        preferred_contact_time: "",
        how_did_you_hear_about_us: "",
        patient_schedule_date: "",
        area_of_concern: "",
        created_by: sessionStorage.getItem("loger_id"),
        // updated_on: formattedDate,
        visibility: "1",
    };

    // console.log(patientData.patient_city);

    const formik = useFormik({
        initialValues,
        validationSchema: basicSchema,
        validate: async (values) => {
            const errors = {};

            // Check Patient email uniqueness
            const isPatientEmailUnique = await checkPatientEmailUniqueness(values.patient_email);
            if (!isPatientEmailUnique) {
                errors.patient_email = "Patient Email is already taken";
            }

            return errors;
        },
        onSubmit: async (formValues) => {
            setIsSubmitting(true);
            toast.dismiss();

            const response = await axios.post(BASE_URL + "API/apicall", {
                security_key: security_key,
                mode: "addPatient",
                // mode: "addPatient___test",
                ...formValues,
                // city_details: cityDetails,
                // checked: checked,
                // checkedService: checkedService,
            });
            const output = response.data;
            if (output == 1) {
                resetFormFields(resetForm, setValues, initialValues);
                setLoading(false);
                await toast.custom((t) => (
                    <div
                        className={`${
                            t.visible ? "animate-enter" : "animate-leave"
                        } d-flex flex-row justify-content-center align-items-center border bg-white rounded border-secondary`}
                        id="toast-notify"
                    >
                        <div className="p-3">
                            <p className="h3">
                                <FontAwesomeIcon icon={faCheckCircle} className="text-success mr-2 me-2" />
                                Thank You!
                            </p>
                            <p className="mt-1 text-sm text-gray-500">Your appointment form has been submitted successfully.</p>
                        </div>
                        <div className="p-3">
                            <button onClick={() => toast.dismiss(t.id)} className="btn btn-success">
                                Close
                            </button>
                        </div>
                    </div>
                    // <div
                    //     className={`${
                    //         t.visible ? "animate-enter" : "animate-leave"
                    //     } d-flex flex-row justify-content-center align-items-center border bg-white w-25 rounded border-secondary`}
                    // >
                    //     <div className="flex-1 w-0 p-4 w-75">
                    //         <div className="flex items-start">
                    //             <div className="ml-3 flex-1">
                    //                 <p className="h3">
                    //                     <FontAwesomeIcon icon={faCheckCircle} className="text-success mr-2 me-2" />
                    //                     Thank You!
                    //                 </p>
                    //                 <p className="mt-1 text-sm text-gray-500">Your appointment form has been submitted successfully.</p>
                    //             </div>
                    //         </div>
                    //     </div>

                    //     <div className="flex border-l border-gray-200 w-25">
                    //         <button onClick={() => toast.dismiss(t.id)} className="btn btn-success">
                    //             Close
                    //         </button>
                    //     </div>
                    // </div>
                ));

                setTimeout(() => {
                    navigate("/");
                }, 1500);
            } else {
                toast.error("Oops! Something went wrong.", {
                    autoClose: 3000,
                });
            }
            setTimeout(function () {
                setIsSubmitting(false);
            }, 1500);
        },
    });

    // Now, you can set variables as needed:
    const values = formik.values;
    const handleBlur = formik.handleBlur;
    const touched = formik.touched;
    const handleChange = formik.handleChange;
    const errors = formik.errors;
    const handleSubmit = formik.handleSubmit;
    const setTouched = formik.setTouched;
    const resetForm = formik.resetForm;
    const setValues = formik.setValues;
    const handleReset = formik.handleReset;

    useEffect(() => {
        if (!formik.isSubmitting) return;
        if (Object.keys(formik.errors).length > 0) {
            const firstErrorField = Object.keys(formik.errors)[0];
            //const firstErrorFieldId = `${firstErrorField}Id`;
            // console.log(firstErrorField);

            const errorFieldElement = document.getElementById(firstErrorField);

            if (errorFieldElement) {
                const elementRect = errorFieldElement.getBoundingClientRect();
                const absoluteElementTop = elementRect.top + window.scrollY;
                const middleOfElement = absoluteElementTop - window.innerHeight / 2;

                window.scrollTo({
                    top: middleOfElement,
                    behavior: "smooth",
                });
            } else {
                // console.error(`Element with ID ${firstErrorField} not found in the DOM.`);
            }
            //document.getElementById(Object.keys(formik.errors)[0]).focus();
        }
    }, [formik]);

    const currentDate = new Date().toISOString().split("T")[0];

    return (
        <div className="patient-container">
            <Toaster position="top-center" />

            {/* <nav className="navbar navbar-default navbar-fixed-top" style={{ border: "1px solid rgb(231, 231, 231)" }}>
                <div className="container d-block d-sm-flex">
                    <div className="navbar-header mobile-center">
                        <div className="navbar-brand p-0 m-0">
                            <img src="images/logo-dark.png" alt="Emstyle" width="150px" />{" "}
                        </div>
                    </div>
                    <div className="click-to-call hidden-xs py-3">
                        <div>
                            <span className="phone-icon">
                                <i className="fa fa-phone" />
                            </span>{" "}
                            <span>1800 755 5775</span>
                        </div>
                    </div>
                </div>
            </nav> */}

            {/* end .header */}
            <div className="container-fluid mt-2">
                <div className="row justify-content-center">
                    <div className="col-sm-10">
                        <div className="content-wrapper">
                            <div className="container-fluid">
                                <div className="section-header d-flex align-items-center justify-content-between">
                                    <div className="section-heading">
                                        <h4 className="mb-1">Appointment Form</h4>
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <a role="button" onClick={() => navigate("/")}>
                                                        Home
                                                    </a>
                                                </li>
                                                <li className="breadcrumb-item active" aria-current="page">
                                                    Appointment Form
                                                </li>
                                            </ol>
                                        </nav>
                                    </div>
                                    <div>
                                        <button className="btn btn-light btn-md border" onClick={() => navigate("/")}>
                                            Back
                                        </button>
                                    </div>
                                </div>
                                <Row>
                                    <Col md={12} className="mx-auto">
                                        <div className="card p-5 mb-5">
                                            <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
                                                <Row className="mb-3">
                                                    <Col md={6}>
                                                        <Form.Group controlId="patient_first_name">
                                                            <Form.Label>
                                                                First Name<span className="text-danger">*</span>
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="patient_first_name"
                                                                value={values.patient_first_name}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className={errors.patient_first_name && touched.patient_first_name ? "input-error" : ""}
                                                            />
                                                            {errors.patient_first_name && touched.patient_first_name && (
                                                                <p className="error">{errors.patient_first_name}</p>
                                                            )}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group controlId="patient_last_name">
                                                            <Form.Label>
                                                                Last Name<span className="text-danger">*</span>
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="patient_last_name"
                                                                value={values.patient_last_name}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className={errors.patient_last_name && touched.patient_last_name ? "input-error" : ""}
                                                            />
                                                            {errors.patient_last_name && touched.patient_last_name && (
                                                                <p className="error">{errors.patient_last_name}</p>
                                                            )}
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col md={6}>
                                                        <Form.Group controlId="patient_email">
                                                            <Form.Label>
                                                                Email<span className="text-danger">*</span>
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="email"
                                                                name="patient_email"
                                                                value={values.patient_email}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className={errors.patient_email && touched.patient_email ? "input-error" : ""}
                                                            />
                                                            {errors.patient_email && touched.patient_email && <p className="error">{errors.patient_email}</p>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group controlId="patient_mobile_phone">
                                                            <Form.Label>
                                                                Patient Mobile Phone<span className="text-danger">*</span>
                                                            </Form.Label>
                                                            <PhoneInput
                                                                inputProps={{
                                                                    id: "patient_mobile_phone",
                                                                }}
                                                                name="patient_mobile_phone"
                                                                countryCodeEditable={false}
                                                                country="us"
                                                                value={values.patient_mobile_phone}
                                                                onlyCountries={["us"]}
                                                                onChange={(value) => {
                                                                    const event = {
                                                                        target: {
                                                                            name: "patient_mobile_phone",
                                                                            value: value,
                                                                        },
                                                                    };
                                                                    handleChange(event);
                                                                }}
                                                                onBlur={(event) => {
                                                                    handleBlur(event);
                                                                    setTouched({ ...touched, patient_mobile_phone: true });
                                                                }}
                                                                inputClass={
                                                                    errors.patient_mobile_phone && touched.patient_mobile_phone
                                                                        ? "input-error form-control"
                                                                        : "form-control"
                                                                }
                                                            />
                                                            {errors.patient_mobile_phone && touched.patient_mobile_phone && (
                                                                <p className="error">{errors.patient_mobile_phone}</p>
                                                            )}
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row className="mb-3">
                                                    <Col md={6}>
                                                        <Form.Group controlId="urgency">
                                                            <Form.Label>
                                                                Urgency<span className="text-danger">*</span>
                                                            </Form.Label>
                                                            <div className="radio-group">
                                                                <Form.Check
                                                                    type="radio"
                                                                    id="urgencyASAP"
                                                                    label="ASAP"
                                                                    name="urgency"
                                                                    value="ASAP"
                                                                    checked={values.urgency === "ASAP"}
                                                                    onChange={handleChange}
                                                                />
                                                                <Form.Check
                                                                    type="radio"
                                                                    id="urgencyOther"
                                                                    label="Other"
                                                                    name="urgency"
                                                                    value="Other"
                                                                    checked={values.urgency === "Other"}
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                            {errors.urgency && touched.urgency && <p className="error">{errors.urgency}</p>}
                                                        </Form.Group>
                                                    </Col>

                                                    <Col sm={6}>
                                                        <Form.Group controlId="area_of_concern">
                                                            <Form.Label>
                                                                Area of Concern<span className="text-danger">*</span>
                                                            </Form.Label>
                                                            <Form.Control
                                                                as="textarea"
                                                                style={{ height: "100px" }}
                                                                rows={3}
                                                                name="area_of_concern"
                                                                value={values.area_of_concern}
                                                                onChange={handleChange}
                                                                // onBlur={handleBlur}
                                                                onBlur={(e) => {
                                                                    const trimmedValue = e.target.value.trim();
                                                                    const event = {
                                                                        target: {
                                                                            name: "area_of_concern",
                                                                            value: trimmedValue,
                                                                        },
                                                                    };
                                                                    handleChange(event);
                                                                    handleBlur(event);
                                                                }}
                                                                className={errors.area_of_concern && touched.area_of_concern ? "input-error" : ""}
                                                            />
                                                            {errors.area_of_concern && touched.area_of_concern && (
                                                                <p className="error">{errors.area_of_concern}</p>
                                                            )}
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                {values.urgency === "Other" && (
                                                    <Row className="mb-3">
                                                        <Col sm={6}>
                                                            <Form.Group controlId="preferred_contact_method">
                                                                <Form.Label>
                                                                    Preferred Contact Method
                                                                    <span className="text-danger">*</span>
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="preferred_contact_method"
                                                                    value={values.preferred_contact_method}
                                                                    onChange={handleChange}
                                                                    // onBlur={handleBlur}
                                                                    onBlur={(e) => {
                                                                        const trimmedValue = e.target.value.trim();
                                                                        const event = {
                                                                            target: {
                                                                                name: "preferred_contact_method",
                                                                                value: trimmedValue,
                                                                            },
                                                                        };
                                                                        handleChange(event);
                                                                        handleBlur(event);
                                                                    }}
                                                                    className={
                                                                        errors.preferred_contact_method && touched.preferred_contact_method ? "input-error" : ""
                                                                    }
                                                                />
                                                                {errors.preferred_contact_method && touched.preferred_contact_method && (
                                                                    <p className="error">{errors.preferred_contact_method}</p>
                                                                )}
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                )}

                                                {/* <Row className="mb-3">
                                                    <Col sm={6}>
                                                        <Form.Group controlId="patient_schedule_date">
                                                            <Form.Label>
                                                                Schedule Date<span className="text-danger">*</span>
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="date"
                                                                name="patient_schedule_date"
                                                                value={values.patient_schedule_date}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                min={currentDate}
                                                                // max={currentDate}
                                                                className={errors.patient_schedule_date && touched.patient_schedule_date ? "input-error" : ""}
                                                            />
                                                            {errors.patient_schedule_date && touched.patient_schedule_date && (
                                                                <p className="error">{errors.patient_schedule_date}</p>
                                                            )}
                                                        </Form.Group>
                                                    </Col>

                                                    <Col md={6}>
                                                        <Form.Group controlId="patient_address_1">
                                                            <Form.Label>
                                                                Patient Address 1<span className="text-danger">*</span>
                                                            </Form.Label>
                                                            <Form.Control
                                                                as="textarea"
                                                                style={{ height: "100px" }}
                                                                value={values.patient_address_1}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className={errors.patient_address_1 && touched.patient_address_1 ? "input-error" : ""}
                                                            />
                                                            {errors.patient_address_1 && touched.patient_address_1 && (
                                                                <p className="error">{errors.patient_address_1}</p>
                                                            )}
                                                        </Form.Group>
                                                    </Col>
                                                </Row> */}

                                                {/* Hide city and state */}

                                                {/* <Row className="mb-3">
                                                    <Col md="6" className="patient_city">
                                                        <Form.Label>City</Form.Label>
                                                        <Select
                                                            options={options_city.filter((option, index) => {
                                                                if (location.state.city_id) {
                                                                    return option.value.split("||")[2].trim() === selectedState.trim();
                                                                } else {
                                                                    return allState.includes(option.value.split("||")[2].trim());
                                                                }
                                                            })}
                                                            name="patient_city"
                                                            id="patient_city"
                                                            value={
                                                                values &&
                                                                values.patient_city &&
                                                                options_city.find((option) => {
                                                                    // alert(values.patient_city);
                                                                    return option.value && option.value.toString() === values.patient_city.toString();
                                                                })
                                                            }
                                                            onChange={(selectedOption) => {
                                                                const event = {
                                                                    target: {
                                                                        name: "patient_city",
                                                                        value: selectedOption.value,
                                                                    },
                                                                };
                                                                handleChange(event);
                                                            }}
                                                            onBlur={(event) => {
                                                                handleBlur(event);
                                                                setTouched({ ...touched, patient_city: true });
                                                            }}
                                                            filterOption={(option, inputValue) => {
                                                                return option.label.toLowerCase().includes(inputValue.toLowerCase());
                                                            }}
                                                            className={errors.patient_city && touched.patient_city ? "input-error" : ""}
                                                        />
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group controlId="patient_state">
                                                            <Form.Label>State</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="patient_state"
                                                                readOnly={true}
                                                                value={values.patient_city.split(" || ")[2]}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className={errors.patient_state && touched.patient_state ? "input-error" : ""}
                                                            />
                                                            {errors.patient_state && touched.patient_state && <p className="error">{errors.patient_state}</p>}
                                                        </Form.Group>
                                                    </Col>
                                                </Row> */}

                                                <Row className="mb-3">
                                                    <Col md={6}>
                                                        <Form.Label>
                                                            Zip Code<span className="text-danger">*</span>
                                                        </Form.Label>
                                                        <InputMask
                                                            mask="99999-9999"
                                                            maskChar=""
                                                            type="text"
                                                            name="patient_zip"
                                                            value={values.patient_zip}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className={`form-control ${errors.patient_zip && touched.patient_zip ? "input-error" : ""}`}
                                                        />
                                                        {errors.patient_zip && touched.patient_zip && <p className="error">{errors.patient_zip}</p>}
                                                    </Col>
                                                </Row>

                                                <Row className="my-4">
                                                    <Col sm={12} className="text-end">
                                                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                                                            <span className="px-4">Submit</span>
                                                            {isSubmitting ? <LinearProgress /> : ""}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PatientAddU3;
